import React from "react";
import { useTheme } from "styled-components";
import {
  storyblokEditable,
  StoryblokComponent
} from "@storyblok/react";

import Section from "@components/layout/Section";
import { getSpacings } from "@utils/getSpacings";

import { SectionStoryblokProps } from "./types";

const SectionStoryblok: React.FC<SectionStoryblokProps> = ({
  blok
}) => {
  const theme = useTheme();

  const {
    body,
    innerVariant,
    backgroundColor,
    textColor,
    isDiv,
    spacingMobileTop,
    spacingMobileBottom,
    spacingTabletTop,
    spacingTabletBottom,
    spacingDesktopTop,
    spacingDesktopBottom,
    scrollTo,
    id
  } = blok || {};

  const spacings = [
    spacingDesktopTop || "XXL",
    spacingDesktopBottom || "XXL",
    spacingMobileTop || "M",
    spacingTabletTop || "L",
    spacingMobileBottom || "M",
    spacingTabletBottom || "L"
  ];

  const { topSpacing, bottomSpacing } = getSpacings(...spacings);

  const sectionBackgroundColor =
    backgroundColor?.color || theme.colors.white;
  const sectionTextColor = textColor?.color || theme.colors.primary;

  const { anchor } = scrollTo || {};

  return body?.length ? (
    <Section
      {...{
        isDiv,
        innerVariant,
        id,
        ...storyblokEditable(blok)
      }}
      backgroundColor={sectionBackgroundColor}
      color={sectionTextColor}
      pt={topSpacing}
      pb={bottomSpacing}
      scrollTo={anchor}
    >
      {body.map((nestedBlok) => (
        <div key={nestedBlok._uid} {...storyblokEditable(nestedBlok)}>
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        </div>
      ))}
    </Section>
  ) : null;
};

export default SectionStoryblok;
